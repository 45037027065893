exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-taxonomy-js": () => import("./../../../src/pages/taxonomy.js" /* webpackChunkName: "component---src-pages-taxonomy-js" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article.template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-articles-template-tsx": () => import("./../../../src/templates/articles.template.tsx" /* webpackChunkName: "component---src-templates-articles-template-tsx" */),
  "component---src-templates-author-template-tsx": () => import("./../../../src/templates/author.template.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */)
}

